require('./bootstrap');
require('video.js');
require('@filmgardi/videojs-markers');
require('slick-slider')

import videojs        from "video.js";
import videojsYoutube from "videojs-youtube";
import Alpine  from 'alpinejs';

$(document).ready(function(){
    $('.posts__list').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<div class="next-arrow"></div>',
        prevArrow: '<div class="prev-arrow"></div>',
        infinite: false,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.085,
                }
            },
        ]
    });

    $('.to-top').click(function (){
        $("html, body, #app").animate({ scrollTop: 0 }, "slow");
        return false;
    })

    $('.cookie__agree').click(function (){
        document.cookie = "cookieAgree=true; path=/; expires=Tue, 19 Jan 2048 03:14:07 GMT";
        $('.cookie').remove();
    })

    $('.tabs__nav__item').click(function (){
        if (!$(this).hasClass('active')) {
            $('.tabs__nav__item').removeClass('active')
            $(this).addClass('active')
            let tab = $(this).attr('data-for-tab');
            $('.tabs__content__item').removeClass('active')
            $(`.tabs__content__item[data-tab="${tab}"]`).addClass('active')

            if ($('.login-page')) {
                if (history.pushState) {
                    var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
                    var newUrl = baseUrl + '?tab='+tab;
                    history.pushState(null, null, newUrl);
                }
            }
        }
    })

    $('.form-row__field.password .show-password').click(function (){
        let input = $(this).parent('.form-row__field').find('input')
        if ($(this).hasClass('showed')) {
            $(this).removeClass('showed')
            input.attr('type', 'password')
        } else {
            $(this).addClass('showed')
            input.attr('type', 'text')
        }
    })

    $('.form-row').each(function () {
        if($(this).find('.form-row__error').length) {
            $(this).addClass('has-error')
        }
    })

    if(!getCookie('cookieAgree')) {
        $('.cookie').show(0)
    }

    let initSlider = false;
    if ($(window).width() <= 700) {
        $('.team__list').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<div class="next-arrow"></div>',
            prevArrow: '<div class="prev-arrow"></div>',
            infinite: false,
            variableWidth: true,
        });
        initSlider = true;
    }

    $(window).resize(function (){
        if ($(window).width() <= 700) {
            initSlider = true;
            $('.team__list').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow: '<div class="next-arrow"></div>',
                prevArrow: '<div class="prev-arrow"></div>',
                infinite: false,
                variableWidth: true,
            });
        } else if (initSlider) {
            $('.team__list').filter('.slick-initialized').slick('unslick');
            initSlider = false;
        }
    })
});

$('.show-more__title').click(function (){
    let parent = $(this).parent();
    parent.find('.show-more__content').slideToggle(500);
})

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

let dropdown = $('.dropdown'),
    dropdownBtn = dropdown.find('.dropdown-btn');

dropdownBtn.click(function (){
    let parent = $(this).parent();
    parent.toggleClass('active');
    parent.find('.dropdown-menu').toggleClass('show');
})
$('.dropdown-menu__close').click(function (){
    $('.dropdown.active').removeClass('active');
    dropdown.find('.dropdown-menu').removeClass('show');
})
$('.content__search__filter-btn').click(function (){
    $('.library__filter').addClass('active-mobile');
})
$('.library__filter__close').click(function (){
    $('.library__filter').removeClass('active-mobile');
})



$(document).click(function(event) {
    var $target = $(event.target);
    if(!$target.closest('.dropdown').length && $('.dropdown.active')) {
        $('.dropdown.active').removeClass('active');
        dropdown.find('.dropdown-menu').removeClass('show');
    }
    if((!$target.closest('.popup__content').length) && $('.popup.show').length) {
        $('.popup.show').removeClass('show');
        $('html').removeClass('open-popup')
    }
});

let popup = $('.popup');
$('.js-close-popup').click(function (){
    popup.removeClass('show')
    $('html').removeClass('open-popup')
})

$('.js-open-popup').click(function (){
    let popupId = $(this).attr('data-popup-id'),
        popupEl = $(`#${popupId}`);
    if (popupEl) {
        setTimeout(function (){
            $('.dropdown.active').removeClass('active');
            dropdown.find('.dropdown-menu').removeClass('show');
            popupEl.addClass('show');
            $('html').addClass('open-popup')
        })
    }

})

$('.js-close-thank-you').click(function (){
    popup.removeClass('show')
    $('html').removeClass('open-popup')
    $('.popup__form__block').show(0);
    $('.popup__form__result').hide(0)
    $('#feedback-popup #feedback-text').val('');
})

$('#feedback-popup #send-feedback').on('click', function() {
    var formData = {
        feedback: $('#feedback-popup #feedback-text').val()
    };
    $('.feedback__row__error').hide(0)
    if (formData.feedback.length <= 3) {
        $('.feedback__row__error').show(0)
    }

    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('#feedback-popup input[name=_token]').val()
        },
        url: '/feedback',
        method: 'POST',
        dataType: 'json',
        data: formData,
        success: function(response) {
            $('.popup__form__block').hide(0);
            $('.popup__form__result').show(0)
        },
    })
});

let timeout;
$(document).on('click', '.shared', function (){
    clearTimeout(timeout)
    let link = $(this).attr('data-link')
    copyToClipboard(link)
    $('.link-copied').addClass('show')
    timeout = setTimeout(function (){
        $('.link-copied').removeClass('show')
    }, 5000)
})
function copyToClipboard(text) {
    var sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text; //save main text in it
    sampleTextarea.select(); //select textarea contenrs
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
}

let listElm = document.querySelector('.library .content__list'),
    app = document.querySelector('#app'),
    loadNow = false,
    currentPage = 1,
    canLoadMore = true;

let loadMore = function() {
    if (!loadNow && canLoadMore) {
        listElm.innerHTML += "<div class='load-more-spinner'><div></div><div></div><div></div><div></div></div>"
        loadNow = true;
        let link = listElm.dataset.loadMore + window.location.search;
        link += window.location.search ? '&' : '?';
        link += 'page=' + ++currentPage;

        $.get( `${link}`, function( response ) {
            $('.load-more-spinner').remove();
            if (response.publications && response.publications.length > 0) {
                response.publications.forEach(i => {
                    let isLections = listElm.dataset.loadMore === '/lections-load'
                    let isLibary = listElm.dataset.loadMore === '/library-load'

                    let link = window.location.origin + '/publications/'+i.id;
                    let actionsElement =
                        `<div class="publication__actions">
                            <div class="publication__actions__item shared" data-link="${link}"></div>
                            <div class="publication__actions__item favorite"></div>
                         </div>`;
                    let typesElements = '';
                    i.types.forEach(t => {
                        typesElements +=`<div class="publication__type ${t}"></div>`
                    })
                    let htmlItem = `
                        <div class="publication">
                            <div class="publication__top">
                                <a href=${link} class="publication__image color-${i.color} ${i.photo ? '' : 'publication__image__empty' }">
                                    <div class="publication__image__photo">
                                        <img src="${i.photo ? i.photo : '/img/logo-ui.png'}" alt="logo" />
                                    </div>
                                    <div class="publication__image__content">
                                        <div class="${i.locale === 'uk' ? 'publication__image__logo' : 'publication__image__logo__global'}"></div>
                                        <div class="publication__image__name">${i.author_names ? i.author_names : ''}</div>
                                    </div>
                                </a>
                                ${actionsElement}
                            </div>
                            <div class="publication__info">
                                <div class="publication__tag ${isLections ? 'p' : 'b'}">${i.genre_name ? i.genre_name : i.genre_id}</div>
                                <div class="publication__divider"></div>
                                <div class="publication__time">${i.date ? i.date : i.created_at}</div>
                                <div class="publication__divider"></div>
                                ${typesElements}
                            </div>
                            <a href=${link} class="publication__name" title="{{ $value->title }}">
                                ${i.title}
                            </a>
                        </div>`;

                    if (isLibary) {
                        let linkUseful =
                        htmlItem = `
                            <div class="useful-link">
                                <div class="useful-link__top">
                                    <div class="useful-link__period">{{$value->theme()->first()->name}}</div>
                                </div>
                                <div class="useful-link__name">{{ $value->name }}</div>
                                <div class="useful-link__authors"></div>
                                <div class="useful-link__flex">
                                    <div class="useful-link__type {{ $value->material_type }}"></div>
                                    @if($value->resource_type === 'EXTERNAL')
                                        <a href="{{ $value->path }}" target="_blank" class="useful-link__href">{{ __("library.follow-material") }}</a>
                                    @else
                                        <a href="{{ $value->path }}" download class="useful-link__download">{{ __("library.upload-material") }}</a>
                                    @endif
                                </div>
                            </div>`
                    }

                    listElm.innerHTML += htmlItem;
                })

                listElm = document.querySelector('.library .content__list');
                setTimeout(()=>{
                    loadNow = false;
                }, 1000)
            } else if (response.libraryMaterials && response.libraryMaterials.length > 0) {
                response.libraryMaterials.forEach(i => {
                    let linkUseful = '';
                    if (i.resource_type === 'EXTERNAL') {
                        linkUseful = `<a href="${i.path}" target="_blank" class="useful-link__href">${i.follow_btn}</a>`
                    } else {
                        linkUseful = `<a href="${i.path}" download class="useful-link__download">${i.upload_btn}</a>`;
                    }
                    let htmlItem = `
                            <div class="useful-link">
                                <div class="useful-link__top">
                                    <div class="useful-link__period">${i.theme}</div>
                                </div>
                                <div class="useful-link__name">${i.name}</div>
                                <div class="useful-link__authors"></div>
                                <div class="useful-link__flex">
                                    <div class="useful-link__type ${i.material_type}"></div>
                                    ${linkUseful}
                                </div>
                            </div>`

                    listElm.innerHTML += htmlItem;
                })

                listElm = document.querySelector('.library .content__list');
                setTimeout(()=>{
                    loadNow = false;
                }, 1000)
            } else {
                canLoadMore = false;
            }
        });
    }
}

// Detect when scrolled to bottom.
if (listElm) {
    app.addEventListener('scroll', function() {
        let elem = document.querySelector('.library .content__list');
        if ((elem.offsetTop + elem.offsetHeight) <= (app.scrollTop + app.offsetHeight + 200)) {
            loadMore();
        }
    });
}

$('.themes-menu-form .form-row__field.checkbox').click(function (){
    setTimeout(()=>{
        $('.themes-menu-form button[type="submit"]').trigger('click');
    })
})
$('.themes-menu-form .form-row__field.radio').click(function (){
    setTimeout(()=>{
        $('.themes-menu-form button[type="submit"]').trigger('click');
    })
})


window.Alpine = Alpine;

Alpine.start();

